import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues, formValueSelector, isValid } from "redux-form";
import { getCodigueraContext } from "../../redux/selectors/Context";
import { getCodiguerasContext, setLoad } from "../../redux/actions/context";
import { showError } from '../../redux/actions/error';
import { eventEvolutionStep3, questionsStep3 } from "../../constants/steps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faSave } from "@fortawesome/free-solid-svg-icons";
import {
  FieldQuestionWithAnswerYesNo,
  FieldSelect,
  FieldText,
  FieldDatePicker,
  FieldSwitch,
  FieldAsyncAutoComplete,
  FieldAutoComplete
} from "../Fields";
import { getCodiguera } from "../../codigueras";
import { Button } from "@material-ui/core";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Col, Row } from "react-flexbox-grid";
import { url_getMedDRALLT } from "../../api/urls";
import api from "../../api";

class RAMEditModal extends Component {
  state = {
    isLoading: false,
    options: [],
    savingMedicine: false
  };
  componentDidMount() {
    if (!this.props.codiguera) this.props.getCodiguerasContext();

    this.setState({
      lltSelected:
        this.props.initialValues && this.props.initialValues.MedraLLT
          ? this.props.initialValues.MedraLLT
          : []
    });
  }
  handleTypeAhead = async (query) => {
    try {
      this.setState({ isLoading: true });
      const response = await api.get(url_getMedDRALLT(query));
      this.setState({ options: response.data });
    } catch (e) {
      this.props.showError(e);
    } finally {
      this.props.setLoad({ isLoading: false });
    }
  }

  handleConfirm = edit => {
    let form = this.props.ram;
    this.setState({ savingMedicine: true })

    if (form.MedraLLT && form.MedraLLT.length > 0) {
      form.MedraLLT = form.MedraLLT[0];
    }
    form.UnitId = form.UnitId === "none" ? null : form.UnitId;
    form.FrecId = form.FrecId === "none" ? null : form.FrecId;
    form.UnitUsoId = form.UnitUsoId === "none" ? null : form.UnitUsoId;
    form.Death = form.Death === "none" ? null : form.Death;
    form.LifeThreading = form.LifeThreading === "none" ? null : form.LifeThreading;
    form.HospitalizationRequired = form.HospitalizationRequired === "none" ? null : form.HospitalizationRequired;
    form.PersistedHospitalization = form.PersistedHospitalization === "none" ? null : form.PersistedHospitalization;
    form.TransitoryDisability = form.TransitoryDisability === "none" ? null : form.TransitoryDisability;
    form.PersistentDisability = form.PersistentDisability === "none" ? null : form.PersistentDisability;
    form.CongenitalAnomaly = form.CongenitalAnomaly === "none" ? null : form.CongenitalAnomaly;
    form.AnotherCondition = form.AnotherCondition === "none" ? null : form.AnotherCondition;
    if (edit) {
      this.props.handleEditRAM(form);
    }
    else {
      this.props.handleAddRAM(form);
    }
  };

  getLLtDefault = () => {
    const { initialValues, ram } = this.props;
    if (
      initialValues.MedraLLT &&
      initialValues.MedraLLT.length > 0 &&
      ram &&
      ram.MedraLLT &&
      ram.MedraLLT.length > 0 &&
      initialValues.MedraLLT[0].llt_code === ram.MedraLLT[0].llt_code
    ) {
      return true;
    }
    return false;
  };
  cancel = () => {
    let ram = this.props.initialValues;
    ram.MedraLLT = ram.MedraLLT !== null ? ram.MedraLLT[0] : null;
    this.props.handleCancelEdit(ram);
    this.props.toggle();
  };
  render() {
    const {
      codiguera,
      open,
      toggle,
      edit,
      loadEdit,
      AccMed,
      anotheCondition,
      considerations,
      hospitalizationRequired,
      validForm
    } = this.props;
    const { lltSelected, savingMedicine } = this.state;
    return (
      <Modal
        size="lg"
        fade={false}
        backdrop={"static"}
        isOpen={open}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Agregar evento</ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={6}>
              {this.getLLtDefault() ? (
                <FieldAutoComplete
                  label="Termino MedDRA"
                  labelKey="llt_name"
                  name="MedraLLT"
                  items={lltSelected}
                  selected={lltSelected}
                />
              ) : (
                <FieldAsyncAutoComplete
                  searchText="Buscando..."
                  emptyLabel="No se encontraron resultados"
                  isLoading={this.state.isLoading}
                  onSearch={this.handleTypeAhead}
                  name="MedraLLT"
                  labelKey="llt_name"
                  items={this.state.options}
                  highlightOnlyResult={true}
                  label="Termino MedDRA"
                  multiple={false}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <FieldText
                name="Description"
                label="Descripción del evento reportable"
                isRequired={true}
                type="textarea"
              />
            </Col>
            <Col sm={4}>
              <FieldSelect
                name="Evolution"
                label="Evolución del evento reportable"
                items={eventEvolutionStep3}
                valueField="Text"
                textField="Text"
              />
            </Col>
            <Col sm={4}>
              <FieldSelect
                name="AccMed"
                label="Acción tomada con el medicamento"
                items={
                  codiguera && getCodiguera("AccionMedicamento", codiguera)
                }
                valueField="Id"
                textField="Nombre"
                typeValue="number"
              />
            </Col>
            <Col sm={4}>
              <FieldSwitch
                name="IsExpected"
                label="¿El evento es esperado?"
                className=""
                icons={null}
              />
            </Col>
            <Col sm={4}>
              <FieldDatePicker
                name="DateStart"
                allowFuture={false}
                allowPast={true}
                label="Inicio del evento reportable"
              />
            </Col>
            <Col sm={4}>
              <FieldDatePicker
                name="DateEnd"
                allowFuture={false}
                allowPast={true}
                label="Finalización del evento reportable"
              />
            </Col>
            <Col sm={4} hidden={hospitalizationRequired !== "true"}>
              <FieldDatePicker
                name="HospitalizationDateStart"
                allowFuture={false}
                allowPast={true}
                label="Fecha de inicio de la hospitalización"
              />
            </Col>
            <Col sm={4} hidden={hospitalizationRequired !== "true"}>
              <FieldDatePicker
                name="HospitalizationDateEnd"
                allowFuture={false}
                allowPast={true}
                label="Fecha de fin de la hospitalización"
              />
            </Col>
            <Col xs={12}>
              <h5 className="m-l-sm m-b-sm">Seriedad del evento reportable:</h5>
            </Col>
            {questionsStep3.map(q => (
              <Col sm={4} key={q.name}>
                <FieldQuestionWithAnswerYesNo
                  name={q.name}
                  label={q.label}
                  info={q.info}
                />
              </Col>
            ))}
          </Row>
          {AccMed >= 2 && AccMed <= 3 && (
            <div>
              <Col lg={4} className="m-l-sm">
                <Row>
                  <Col sm={12} md={4} lg={4}>
                    <Row>
                      <FieldText
                        name="Concentration"
                        label="Concentración"
                        type="number"
                        min={0}
                      />
                    </Row>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Row>
                      <FieldSelect
                        name="UnitId"
                        label="Unidad"
                        items={
                          codiguera &&
                          getCodiguera("MedicamentoUnidad", codiguera)
                        }
                        valueField="Id"
                        textField="Unidad"
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col lg={3}>
                <FieldSelect
                  name="FrecId"
                  label="Frecuencia"
                  items={
                    codiguera &&
                    getCodiguera("MedicamentoFrecuencia", codiguera)
                  }
                  textField="Unidad"
                  valueField="Id"
                />
              </Col>
              <Col lg={3} className="m-l-md">
                <Row>
                  <Col sm={12} md={4} lg={4}>
                    <Row>
                      <FieldText
                        name="Cant"
                        label="Cantidad"
                        type="number"
                        min={0}
                      />
                    </Row>
                  </Col>
                  <Col sm={12} md={8} lg={8}>
                    <Row>
                      <FieldSelect
                        name="UnitUsoId"
                        label="Unidad"
                        items={
                          codiguera &&
                          getCodiguera("MedicamentoUnidadUso", codiguera)
                        }
                        textField="Unidad"
                        valueField="Id"
                      />
                    </Row>
                  </Col>
                </Row>
              </Col>
            </div>
          )}
          {(anotheCondition === "true" ||
            (considerations && considerations.length > 0)) && (
              <Row>
                <Col xs={12}>
                  <FieldText
                    name="Considerations"
                    label="Ingrese otras consecuencias que considere clínicamente significativas para la reacción adversa reportada"
                    type="textarea"
                  />
                </Col>
              </Row>
            )}
        </ModalBody>
        <ModalFooter>
          <Button
            type="button"
            disabled={loadEdit}
            className="card-actions-button button-back"
            variant="contained"
            onClick={this.cancel}
          >
            Cancelar
          </Button>
          <Button
            type="button"
            className="card-actions-button button-next"
            variant="contained"
            onClick={() => this.handleConfirm(edit)}
            disabled={savingMedicine || !validForm}
          >
            Confirmar&nbsp;
            {(savingMedicine || loadEdit) &&(
              <FontAwesomeIcon
                className="m-l-s"
                icon={faSpinner}
                pulse={true}
              />
            )}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

RAMEditModal.propTypes = {
  initialValues: PropTypes.object
};

const mapDispatchToProps = {
  getCodiguerasContext,
  showError,
  setLoad
};

const mapStateToProps = state => ({
  codiguera: getCodigueraContext(state),
  ram: getFormValues("RAMEditModal")(state),
  AccMed: formValueSelector("RAMEditModal")(state, "AccMed"),
  anotheCondition: formValueSelector("RAMEditModal")(state, "AnotheCondition"),
  considerations: formValueSelector("RAMEditModal")(state, "Considerations"),
  hospitalizationRequired: formValueSelector("RAMEditModal")(
    state,
    "HospitalizationRequired"
  ),
  llt: formValueSelector("RAMEditModal")(state, "MedraLLT"),
  validForm: isValid("RAMEditModal")(state)
});

const validate = values => {
  const error = {};
  return error;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "RAMEditModal", validate })(RAMEditModal));
