import React, { Component } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  reduxForm,
  formValueSelector,
  getFormValues,
  isValid,
  getFormError
} from "redux-form";
import {
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import {
  url_getlaboraotriesByProductsCountry,
  url_getPrincipioActivoByFamilia,
  url_postMedicamentoRelacionadoInsert,
  url_postMedicamentoRelacionadoUpdate,
  url_getPrincipioActivoByMR,
  url_deleteMedicamentoRelacionado
} from "../../../../api/urls";
import {
  faArrowLeft,
  faArrowRight,
  faPlus,
  faTimes,
  faSpinner,
  faSave
} from "@fortawesome/free-solid-svg-icons";
import { Label, Input, FormGroup } from "reactstrap";
import {
  FieldSelect,
  FieldText,
  FieldAutoComplete,
  FieldDatePicker,
  iconInfo
} from "../../../Fields";
import {
  getERMedicalSusp,
  setERMedicalSusp,
  updateERMedicalSusp,
  deleteMedicalSusp
} from "../../../../redux/actions/eventoReportable";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import ListMedication from "../../../ListComponents/List/ListMedication";
import { getMedicamentosPais } from "../../../../redux/selectors/list";
import { getERMedicamentosSosp, getER } from "../../../../redux/selectors/ER";
import { getCodigueraContext } from "../../../../redux/selectors/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";
import { getCodiguera } from "../../../../codigueras";
import {
  getCodiguerasContext,
  setLoad
} from "../../../../redux/actions/context";
import {
  showError
} from '../../../../redux/actions/error';
import api from "../../../../api";
import { compareSort } from './../../../../helpers/utilities';
import { DateTime } from "luxon";
import MomentTimezone from "moment-timezone";


class SuspectMedications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      prinActiv: "",
      modal: false,
      laboratorios: [],
      principiosActivos: null,
      IdMR: null,
      medicSelected: null,
      savingSuspectMedicine: false,
      dateIni: null,
      dateEnd: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.change("Medicamento[0]", null);
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleChangeMedication = (med, array) => {
    if (array.length > 0) {
      this.getMedicationLaboratory(array, true);
    } else {
      this.props.change("PrincipiosActivos", null);
      this.props.change("Medicamento[0]", null);
      this.props.change("LineaId", null);
      this.setState({
        prinActiv: "",
        laboratorios: [],
        principiosActivos: null,
        medicSelected: null
      });
    }
  };

  getMedicationLaboratory = async (med, loadDefault) => {
    try{
      if (med && med[0]) {
        this.props.setLoad(true);
        this.setState({ prinActiv: med[0].Family.Name });
        const response = await api.get(url_getlaboraotriesByProductsCountry(med[0].Id, this.props.country));
        this.setState({ laboratorios: response.data });
        if(response.data.length === 1){
          this.props.change("LineaId", response.data[0].Linea_Id);
        }
        if(response.data.length === 2){
          this.props.change("LineaId", response.data[0].Linea_Id);
        }
        if(loadDefault || (this.props.principios && this.props.principios.length === 0)){
          const response = await api.get(url_getPrincipioActivoByFamilia(med[0].Family.Id));
          this.props.change("PrincipiosActivos", response.data);
        }
      }
    }catch(error){
      this.props.showError(error);
    }finally{
      this.props.setLoad(false);
    }
  };

  resetMedicalSusp = () => {
    this.props.change("Medicamento[0]", null);
    this.props.change("LineaId", null);
    this.props.change("Indication", null);
    this.props.change("DateStart", null);
    this.props.change("DateEnd", null);
    this.props.change("ViaId", null);
    this.props.change("FrecTipo", null);
    this.props.change("FrecuenciaTipo", null);
    this.props.change("FrecId", null);
    this.props.change("Cant", null);
    this.props.change("UnitUsoId", null);
    this.props.change("PrincipiosActivos", []);
    this.setState({
      modal: true,
      IdMR: null,
      medicSelected: null,
      prinActiv: ""
    });
  };

  loadMedicalSusp = async item => {
    try{
      this.props.setLoad(true);
      this.setState({ loadMed: true, IdMR: item.Id});
      const response = await api.get(url_getPrincipioActivoByMR(item.Id));
      this.getMedicationLaboratory([item.Medicine], false);
      this.props.change("Medicamento[0]", item.Medicine);
      this.props.change("LineaId", item.LineaId);
      this.props.change("Indication", item.Indication);
      this.props.change("DateStart", item.DateStart);
      this.props.change("DateEnd", item.DateEnd);
      this.props.change("ViaId", item.ViaId);
      this.props.change("FrecuenciaTipo", item.FrecuenciaTipo);
      this.props.change("FrecId", item.FrecId);
      this.props.change("Cant", item.Cant);
      this.props.change("UnitUsoId", item.UnitUsoId);
      this.props.change("PrincipiosActivos", response.data);
      window.scrollTo(0, 150);
      this.setState({
        modal: true,
        medicSelected: [item.Medicine]
      });

    }catch(error){
      this.props.showError(error);
    }finally{
      this.props.setLoad(false);
      this.setState({ loadMed: true, IdMR: item.Id });
    }
  };

  deleteMedicalSusp = async medication => {
    try{
      const response = await api.post(url_deleteMedicamentoRelacionado, medication);
      this.props.deleteMedicalSusp(response.data);
    }catch(error){
      this.props.showError(error);
    }
  };

  handleAction = (action, medication) => {
    switch (action) {
      case "edit": {
        this.loadMedicalSusp(medication);
        break;
      }
      case "delete": {
        this.deleteMedicalSusp(medication);
        break;
      }
      default:
        break;
    }
  };

  handleSaveMedicamento = async () => {
    try{
      if (this.props.validForm) {
        this.props.setLoad(true);
        this.setState({savingSuspectMedicine: true});
        const medRel = {
          Id: this.state.IdMR,
          IdER: this.props.er.Id,
          Medicine: { Id: this.props.medicamento.Id },
          ERType: 1,
          LineaId: this.props.medRelacionado.LineaId,
          LineaId2: this.props.medRelacionado.LineaId2,
          Indication: this.props.medRelacionado.Indication,
          PrincipiosActivos: this.props.medRelacionado.PrincipiosActivos,
          DateStart: this.props.medRelacionado.DateStart,
          DateEnd: this.props.medRelacionado.DateEnd,
          ViaId: this.props.medRelacionado.ViaId,
          FrecuenciaTipo: this.props.medRelacionado.FrecuenciaTipo,
          FrecId: this.props.medRelacionado.FrecId,
          Cant: this.props.medRelacionado.Cant,
          UnitUsoId: this.props.medRelacionado.UnitUsoId,
          LotNumber: ""
        };
        const url = this.state.IdMR
          ? url_postMedicamentoRelacionadoUpdate
          : url_postMedicamentoRelacionadoInsert;

        const response = await api.post(url, medRel);
        if(url === url_postMedicamentoRelacionadoUpdate){
          this.props.updateERMedicalSusp(response.data);
        }else{
          this.props.setERMedicalSusp(response.data);
        }
        this.props.change("medRelacionado", null);
        this.setState({
            modal: false,
            IdMR: null
        });
      }
    }catch(error){
      this.setState({ IdMR: null });
      this.props.showError(error);
    }finally{
      this.props.setLoad(false);
      this.setState({savingSuspectMedicine: false});
    }
  }

  render() {
    const {
      title,
      handleSubmit,
      handleBack,
      medicamentos,
      codiguera,
      loadNext,
      principios,
      validForm
    } = this.props;
    const { prinActiv, laboratorios, medicSelected, savingSuspectMedicine } = this.state;
    const labOptions = laboratorios.map((l) => ({value: l.Linea_Nombre, label: l.Linea_Nombre}))
    return (
      <div>
        <CardHeader className="p-l-n" title={title} />

        {(this.state.modal ||
          !(this.props.relacionados && this.props.relacionados.length > 0)) && (
          <div>
            <CardContent>
              <Row>
                <Col lg={3}>
                  {medicSelected && medicSelected.length > 0 ? (
                    <FieldAutoComplete
                      multiple={false}
                      selected={medicSelected}
                      isRequired={true}
                      name="Medicamento"
                      label="Medicamento"
                      highlightOnlyResult={true}
                      items={medicamentos}
                      onChange={this.handleChangeMedication}
                      labelKey="ComercialName"
                    />
                  ) : (
                    <FieldAutoComplete
                      multiple={false}
                      isRequired={true}
                      name="Medicamento"
                      label="Medicamento "
                      highlightOnlyResult={true}
                      items={medicamentos}
                      onChange={this.handleChangeMedication}
                      labelKey="ComercialName"
                    />
                  )}
                </Col>
                &nbsp; 
                &nbsp; 
                &nbsp; 
                <Col lg={3}>
                  {laboratorios && laboratorios.length > 0 ? (
                    <Col>
                      <Label>Fabricante:</Label> 
                      <Select 
                        isMulti
                        closeMenuOnSelect = {false}
                        options={labOptions}
                        defaultValue={[labOptions[0], labOptions[1]]}
                        valuefield="Linea_Id"
                        name="LineaId"
                        onChange={console.log(labOptions[0])}
                      />
                    </Col>
                  ) : (
                    <Col lg={1}></Col>
                  )}
                </Col>
                <Col lg={3}>
                  <FieldText
                     name={`Lote`}
                     label="Lote"
                     type="text"
                  />
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label>Familia</Label>
                    <Input value={prinActiv} readOnly={true} />
                  </FormGroup>
                </Col>
                <Col lg={6} md={8} sm={12}>
                  {principios &&
                    principios.map((p, i) => (
                      <Row key={i}>
                        <Col sm={12} md={4} lg={6}>
                          <FormGroup>
                            <Label>Principio Activo</Label>
                            <Input
                              type="text"
                              value={p.PrincipioActivo_Nombre}
                              disabled={true}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={12} md={8} lg={6}>
                          <Row>
                            <Col sm={6}>
                              <FieldText
                                name={`PrincipiosActivos[${i}].Concentracion`}
                                label="Concentración"
                                min="0"
                                step="0.001"
                                type="number"
                                value={p.Concentracion}
                              />
                            </Col>
                            <Col sm={6}>
                              <FieldSelect
                                typeValue="number"
                                name={`PrincipiosActivos[${i}].Unidad`}
                                label="Unidad"
                                items={
                                  codiguera &&
                                  getCodiguera("MedicamentoUnidad", codiguera)
                                }
                                valueField="Id"
                                textField={"Unidad"}
                                onChange={this.handleChangePrincipioActivo}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={3}>
                  <FieldDatePicker
                    name="DateStart"
                    allowFuture={false}
                    allowPast={true}
                    label="Inicio de la administración"
                    onChange={(event, value)=>{this.setState({dateIni: value})}}
                  />
                </Col>
                <Col sm={12} md={3}>
                  <FieldDatePicker
                    name="DateEnd"
                    allowFuture={false}
                    allowPast={true}
                    onChange={(event, value)=>{if(value < this.state.dateIni)event.preventDefault()}} 
                    label="Fin de la administración"
                  />
                </Col>
                <Col sm={12} md={2}>
                  <FieldText
                    name="Cant"
                    typeValue="number"
                    type="number"
                    min={0}
                    label={
                      <span>
                        {"Dosis administrada"}{" "}
                        {iconInfo(
                          "Ésta dosis corresponde a la dosis administrada en cada instancia posológica. Por ejemplo: si se administran 50mg cada 12 horas, aquí se colocará el número 50"
                        )}
                      </span>
                    }
                  />
                </Col>
                <Col sm={12} md={3}>
                  <FieldSelect
                    name="UnitUsoId"
                    label="Unidad de dosis"
                    items={
                      codiguera &&
                      getCodiguera("MedicamentoUnidadUso", codiguera)
                    }
                    textField="Unidad"
                    valueField="Id"
                    typeValue="number"
                  />
                </Col>
                <Col sm={12} md={3}>
                  <FieldSelect
                    name="ViaId"
                    label="Vía de administración"
                    items={codiguera && getCodiguera("Via", codiguera)}
                    textField="Descripcion"
                    valueField="Id"
                    typeValue="number"
                  />
                </Col>
                <Col sm={12} md={3}>
                  <FieldSelect
                    name="FrecuenciaTipo"
                    label="Frecuencia de administración"
                    items={
                      codiguera &&
                      getCodiguera("MedicamentoFrecuencia", codiguera)
                    }
                    valueField="Id"
                    textField="Unidad"
                    typeValue="number"
                  />
                </Col>
                <Col sm={12} md={2}>
                  <FieldText 
                    name="FrecId" 
                    label="Horas" 
                    type="number" 
                    min={0}
                    />
                </Col>

                <Col lg={12}>
                  <FieldText
                    name="Indication"
                    label="Motivo de la indicación"
                    type="textarea"
                  />
                </Col>
              </Row>
            </CardContent>
            <CardActions className="card-actions p-t m-b-md">
              <div>
                <Button
                  type="button"
                  className="card-actions-button button-back"
                  variant="contained"
                  onClick={this.toggle}
                >
                  <FontAwesomeIcon size="lg" icon={faTimes} />
                </Button>
                {validForm && (
                  <Button
                    type="button"
                    className="card-actions-button button-next"
                    variant="contained"
                    onClick={this.handleSaveMedicamento} 
                    disabled ={savingSuspectMedicine}
                  >
                    <FontAwesomeIcon size="lg" icon={savingSuspectMedicine ? faSpinner : faSave}/>
                  </Button>
                )}
              </div>
            </CardActions>
          </div>
        )}
        <Row>
          <div className="text-right m-b-sm">
            {this.props.relacionados && (
              <Button
                type="button"
                className="card-actions-button button-next"
                onClick={() => this.resetMedicalSusp()}
                variant="contained"
              >
                <FontAwesomeIcon icon={faPlus} className="m-r-md" />
                Agregar medicamento
              </Button>
            )}
          </div>
          {
            <ListMedication
              list={this.props.relacionados}
              View={"edit"}
              handleAction={this.handleAction}
            />
          }
        </Row>
        <CardActions className="card-actions p-t-md">
          <div>
            <Button
              type="button"
              disabled={loadNext}
              className="card-actions-button button-back"
              variant="contained"
              onClick={handleBack}
            >
              <FontAwesomeIcon size="lg" icon={faArrowLeft} />
            </Button>
            <Button
              type="button"
              disabled={loadNext || !this.props.relacionados}
              className="card-actions-button button-next"
              variant="contained"
              onClick={handleSubmit}
            >
              <FontAwesomeIcon
                size="lg"
                icon={loadNext ? faSpinner : faArrowRight}
                pulse={loadNext}
              />
            </Button>
          </div>
        </CardActions>
      </div>
    );
  }
}

SuspectMedications.propTypes = {
  title: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  relacionados: PropTypes.array,
  medicamentos: PropTypes.array
};

const mapDispatchToProps = {
  getCodiguerasContext,
  getERMedicalSusp,
  setERMedicalSusp,
  updateERMedicalSusp,
  deleteMedicalSusp,
  setLoad,
  showError
};

const mapStateToProps = state => ({
  codiguera: getCodigueraContext(state),
  relacionados: getERMedicamentosSosp(state),
  medicamentos: getMedicamentosPais(state),
  principios: formValueSelector("EReportEditStep2")(state, "PrincipiosActivos"),
  medicamento: formValueSelector("EReportEditStep2")(state, "Medicamento[0]"),
  medRelacionado: getFormValues("EReportEditStep2")(state),
  validForm: isValid("EReportEditStep2")(state),
  errorsForm: getFormError("EReportEditStep2")(state),
  er: getER(state)
});

const validate = values => {
  const error = {};
  if (values.Medicamento && Array.isArray(values.Medicamento)) {
    if (values.Medicamento.length === 0 || values.Medicamento[0] === null) {
      error.Medicamento = "Debe seleccionar un medicamento";
    }
  }
  if (!values.LineaId || values.LineaId === "none") {
    error.LineaId = "Debe seleccionar un laboratorio";
  }

  return error;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: "EReportEditStep2", validate })(SuspectMedications));
