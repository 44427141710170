import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { Row, Col, Grid } from "react-flexbox-grid";
import {
  FieldSwitch,
  FieldSelect,
  FieldDatePicker,
  FieldLabel,
  FieldText
} from "../Fields";
import { Prompt } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  Button,
  FormControlLabel,
  Checkbox,
  MuiThemeProvider
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { getCodiguera } from "../../codigueras";
import { getMyUser } from "../../redux/selectors/Context";
import UploadFile from "../UploadFile";
import { Label } from "reactstrap";
import FileView from "../UploadFile/fileView";
import {
  downloadFileLocalSanitaryNotificactionById,
  downloadFileProviderNotificationById
} from "../../api/urls";
import { theme } from "../../constants/theme";
import { answersNOSI } from "../../constants/steps";

class RAMCalifiactorModal extends Component {
  state = {
    localSanitaryFiles: [],
    providerNotificationFiles: []
  };

  _handleLocalSanitaryAddFile = localSanitaryFiles =>
    this.setState({ localSanitaryFiles });
  _handleProviderAddFile = providerNotificationFiles =>
    this.setState({ providerNotificationFiles });

  Cancel = () => {
    this.props.toggle();
  };

  Save = () => {
    this.props.handleCalificator(this.state);
  };

  _handleCheckType = id => {
    return (
      ((this.state.CategoryRAM &&
        this.state.CategoryRAM.find(x => Number(x.RAMCategoryId) === id)) ||
        null) !== null
    );
  };

  renderCategoryRAM = categories => (
    <Row>
      {categories.length > 0 &&
        categories.map(g => (
          <Col key={g.Id} sm={4}>
            <MuiThemeProvider theme={theme}>
              <FormControlLabel
                className="m-b-n m-t-n"
                value={g.Id.toString()}
                control={
                  <Checkbox
                    className="m-r-sm m-b-n m-t-n"
                    checked={this._handleCheckType(g.Id)}
                    color="primary"
                    onChange={this._handleChangeCheckType}
                    name={g.Id.toString()}
                    title={g.Type}
                  />
                }
                label={g.Type}
                labelPlacement="end"
              />
            </MuiThemeProvider>
          </Col>
        ))}{" "}
    </Row>
  );

  render() {
    const { props } = this;
    return (
      <Modal
        size={"lg"}
        fade={false}
        isOpen={props.open}
        toggle={() => props.toggle()}
      >
        <ModalHeader>
          Clasificar RAM:{" "}
          <span
            title={props.initialValues.Description}
            className="text-limit-200 text-muted"
          >
            {props.initialValues.Description}
          </span>
        </ModalHeader>
        <ModalBody>
          <Grid>
            <Row>
              <Col
                sm={12}
                md={6}
                lg={2}
                title="¿Se trata de una reacción adversa?"
              >
                <FieldSelect
                  name="IsRAM"
                  label="¿Es RAM?"
                  items={answersNOSI}
                  valueField="Id"
                  textField="Text"
                />
              </Col>
              <Col
                sm={12}
                md={6}
                lg={2}
                title="¿La reacción adversa es esperada?"
                hidden={(!props.isRAM) || (props.isRAM === "false")}
              >
                <FieldSwitch
                  label="¿Es esperada?"
                  name="IsExpected"
                  icons={null}
                />
              </Col>
              <Col sm={12} md={6} lg={3} hidden={(!props.isRAM) || (props.isRAM === "false")}>
                <FieldSelect
                  disabled={(!props.isRAM) || (props.isRAM === "false")}
                  name="TypeRAM.Id"
                  label="Tipo de reacción adversa"
                  items={
                    props.codiguera && getCodiguera("RAMTipos", props.codiguera)
                  }
                  textField="Type"
                  valueField="Id"
                  typeValue="number"
                />
              </Col>
              <Col sm={12} md={6} lg={3}>
                <FieldLabel
                  label="Termino MedDRA"
                  value={props.medraLLT ? props.medraLLT.llt_name : "---"}
                  withOutStrong={true}
                />
              </Col>
              <Col sm={12} md={6} lg={2}>
                <FieldLabel
                  label="Categoría"
                  value={
                    props.category && props.codiguera
                      ? props.category.Type
                      : "---"
                  }
                  withOutStrong={true}
                />
              </Col>
            </Row>
            <h5>Notificaciones realizadas</h5>
            <Row>
              <Col sm={12} md={4}>
                <FieldSwitch
                  label="Autoridad sanitaria local"
                  name="LocalSanitaryNotificaction"
                  icons={null}
                />
              </Col>
              <Col xs={12} sm={6} md={4} hidden={!props.lSNotificaction}>
                <FieldDatePicker
                  label="Fecha"
                  name="LocalSanitaryNotificactionFecha"
                  allowPast={true}
                  allowFuture={false}
                />
              </Col>
              <Col xs={12} sm={6} md={4} hidden={!props.lSNotificaction}>
                <FieldSelect
                  label="Vía"
                  name="LocalSanitaryNotificactionVia"
                  items={props.codiguera.ViaDeComunicacion}
                  textField="Nombre"
                  valueField="Id"
                  typeValue="number"
                />
              </Col>
              <Col sm={12} hidden={!props.lSNotificaction}>
                {!props.initialValues.LocalSanitaryNotificactionFile ? (
                  <UploadFile
                    label="Documento comprobante"
                    handleAddFile={this._handleLocalSanitaryAddFile}
                    multiple={false}
                  />
                ) : (
                  <div className="m-b-sm">
                    <Label>Coprobante adjunto</Label>
                    <FileView
                      url={downloadFileLocalSanitaryNotificactionById(
                        props.initialValues.Id
                      )}
                      fileName={
                        props.initialValues.LocalSanitaryNotificactionFileName
                      }
                      type={
                        props.initialValues.LocalSanitaryNotificactionExtension
                      }
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12} lg={4}>
                <FieldSwitch
                  disabled={props.user.Rol !== "QPPV"}
                  label="Licenciante"
                  name="ProviderNotification"
                  icons={null}
                />
              </Col>
              <Col xs={12} sm={6} md={4} hidden={!props.pNotificaction}>
                <FieldDatePicker
                  label="Fecha"
                  name="ProviderNotificationFecha"
                  allowPast={true}
                  allowFuture={false}
                />
              </Col>
              <Col xs={12} sm={6} md={4} hidden={!props.pNotificaction}>
                <FieldSelect
                  label="Vía"
                  name="ProviderNotificationVia"
                  items={props.codiguera.ViaDeComunicacion}
                  textField="Nombre"
                  valueField="Id"
                  typeValue="number"
                />
              </Col>
              <Col sm={12} hidden={!props.pNotificaction}>
                {!props.initialValues.ProviderNotificationFile ? (
                  <UploadFile
                    label="Documento comprobante"
                    handleAddFile={this._handleProviderAddFile}
                    multiple={false}
                  />
                ) : (
                  <div>
                    <Label>Archivo</Label>
                    <FileView
                      url={downloadFileProviderNotificationById(
                        props.initialValues.Id
                      )}
                      fileName={
                        props.initialValues.ProviderNotificationFileName
                      }
                      type={props.initialValues.ProviderNotificationExtension}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <FieldText
                  label="Reclamo de calidad"
                  name="QualityClaim"
                  type="text"
                />
              </Col>
            </Row>
          </Grid>
          <Prompt
            when={!props.pristine}
            message={
              "¡Se perderán los cambios en la calificación RAM sí continúa!"
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={props.upload}
            type="button"
            className="card-actions-button button-back"
            onClick={this.Cancel}
            variant="contained"
          >
            Cancelar
          </Button>
          <Button
            disabled={props.upload}
            type="button"
            className="card-actions-button button-next"
            onClick={this.Save}
            variant="contained"
          >
            Guardar{" "}
            {props.upload && (
              <FontAwesomeIcon
                className="m-l-sm"
                size="lg"
                icon={faSpinner}
                pulse={props.upload}
              />
            )}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const validate = values => {
  const error = {};
  return error;
};

const mapStateToProps = state => ({
  RAMId: formValueSelector("RAMCalificator")(state, "Id"),
  isRAM: formValueSelector("RAMCalificator")(state, "IsRAM"),
  lSNotificaction: formValueSelector("RAMCalificator")(
    state,
    "LocalSanitaryNotificaction"
  ),
  pNotificaction: formValueSelector("RAMCalificator")(
    state,
    "ProviderNotification"
  ),
  category: formValueSelector("RAMCalificator")(state, "CategoryRAM"),
  medraLLT: formValueSelector("RAMCalificator")(state, "MedraLLT"),
  user: getMyUser(state)
});

export default connect(
  mapStateToProps,
  null
)(reduxForm({ form: "RAMCalificator", validate })(RAMCalifiactorModal));
